$bootstrap-sass-asset-helper: false !default;

$title-color: #101115;
$desc-color: #555555;
$theme-color: #41494e;
$primary-color: #f7f7f7;
$border-color: #ecf0f3;
$white-color: #fff;
$black-color: #000000;
$ash-color: #f9f9f9;
$bg-color: #f5f9ff;
$body-color: #391965;
$grey-color: #555555;
$shadow-color: #888888;

// social-media color
$facebook: #3b5998;
$twitter: #55acee;
$linkedin: #007bb5;
$skype: #2fb7df;
$youtube: #41494e;
$google: #41494e;
$camera: #41494e;
$instagram: #41494e;
$tumblr: #34465d;
$behance: #1769ff;
$dribbble: #41494e;
$star: #e9ba17;
$green: #f2f3f2;
$red: #41494e;
$yellow: #ffdf40;
$nell: #212fb4;
$navy: #e6e7eb;
$plaseholder: #a0a6bc;
$pinterest : #41494e;
$basketball : #41494e;
$globe : #41494e;
$vimeo : #3b5998;
//== Typography


//Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap');

// font-family
$font-b: 'Barlow', sans-serif;

// font-size
$fs-base: 1rem !default;
$fs-large: 1rem; //16px;
$fs-small: 0.875rem; //14px
$fs-h1: 3.75rem; //60px;
$fs-h2: 2.5rem; //40px;	
$fs-h3: 1.875rem; //30px;		
$fs-h4: 1.5rem; //24px;	
$fs-h5: 1.25rem; //20px;		
$fs-h6: 1.125rem; //18px;		

// heading line height
$lh-h1: 72px;
$lh-h2: 42px;
$lh-h3: 48px;
$lh-h4: 32px;
$lh-h5: 24px;
$lh-h6: 26px;

// Description line height
$lh-p: 24px;

//  transition
$transition: all 0.3s ease;